import React, { useState, useEffect } from 'react';
import {BsStars} from "react-icons/bs";
import '../../css/Pricing.css'
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import i18n from '../../languages/i18n';
import {eventGoogleAnalytics} from "../../registration/analytics";

const Pricing = () => {
    const [shopData, setShopData] = useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const features = t('Pricing.Features', { returnObjects: true });
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const uid = localStorage.getItem('uid');
            const email = localStorage.getItem('email');

            const response = await fetch('https://gostlink.com/api/profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uid, email }),
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.userInfo);
                // Проверяем, если подписка активна
                if (data.userInfo.subscriptionIsActive) {
                    // navigate('/profile');
                }
            }
        };

        fetchUserData();
    }, [navigate]);


    useEffect(() => {
        const requestData = {
            uid: '',
            email: '',
        };
        fetchShopData(requestData);
    }, []);

    const fetchShopData = (userData) => {

        fetch(`https://gostlink.com/api/shop/getShop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then(response => {
                console.log(response);
                return response.text();
            })
            .then(text => {
                console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    setShopData(data);
                } else {
                    // navigate(`/`);
                    console.log('ошибка тут')
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                // navigate(`/`);
                console.log('нет тут')
            });
    };


    const pay = (firstPrice, subPrice) => {
        const userEmail = localStorage.getItem('email') || '';
        var widget = new window.cp.CloudPayments({
            language: i18n.language === 'ru' ? 'ru-RU' : 'en-US',
            email: userEmail,
            applePaySupport: false,
            googlePaySupport: false,
            yandexPaySupport: false,
            tinkoffPaySupport: true,
            tinkoffInstallmentSupport: false,
            sbpSupport: true
        });

        var receipt = { // чекуля первого платежа
            Items: [//товарные позиции
                {
                    label: t('Payment.Label'),
                    price: firstPrice,
                    quantity: 1.00,
                    amount: firstPrice,
                    vat: 0,
                    method: 0,
                    object: 0,
                }
            ],
            taxationSystem: 0,
            email: localStorage.getItem('email'),
            phone: '',
            isBso: false,
            amounts: {
                electronic: firstPrice,
                advancePayment: 0.00,
                credit: 0.00,
                provision: 0.00
            }
        };

        var receiptRecurent = { // чекуля рекуретная
            Items: [//товарные позиции
                {
                    label: t('Payment.Label'),
                    price: subPrice,
                    quantity: 1.00,
                    amount: subPrice,
                    vat: 0,
                    method: 0,
                    object: 0,
                }
            ],
            taxationSystem: 0,
            email: localStorage.getItem('email'),
            phone: '',
            isBso: false,
            amounts: {
                electronic: subPrice,
                advancePayment: 0.00,
                credit: 0.00,
                provision: 0.00
            }
        };

        var data = {};
        data.CloudPayments = {
            CustomerReceipt: receipt, //чек для первого платежа
            recurrent: {
                interval: 'Day',
                period: 1,
                customerReceipt: receiptRecurent //чек для регулярных платежей
            }
        }; //создание ежемесячной подписки

        widget.charge({ // options
                publicId: 'pk_4a8b35334f78ae1f4ca1dbd3650c4', //id из личного кабинета
                description: "С еловым спасом, коллеги!!!", //назначение
                amount: firstPrice, //сумма
                currency: 'RUB',
                accountId: userEmail, //идентификатор плательщика (обязательно для создания подписки)
                data: data
            },
            function (options) { // success
                eventGoogleAnalytics('purchase', 'shop', 'purchase', firstPrice);
                //действие при успешной оплате
                //addGoogleAnalytics('pay', 'shop', 'fp:' + firstPrice + '; sp:' + subPrice );
                navigate('/profile');
            },
            function (reason, options) { // fail
                //действие при неуспешной оплате
                toast.error(reason);
            });
    };


return (
    <div className="row-main">
        {userData ? (
            <>
                { shopData.success ? (
                    <div className="card">
                        <div className="card-header">
                            <span className="discount-badge" style={{backgroundColor: '#e6f4ea', color: '#2f855a'}}>
                                С еловым спасом!
                            </span>
                            <h3 className="card-title">
                                {t('Pricing.Type')}
                            </h3>

                            <div className="price">
                                <span className="price-value">20</span>
                                <span className="price-symbol">{t('Pricing.Symbol-month')}</span>
                            </div>
                            <div className="price-old">{shopData.strikethroughPrice} {t('Pricing.Symbol-month')}</div>

                        </div>
                        <div className="card-body">
                            <table>
                                <tbody>
                                {features.map((feature, index) => (
                                    <tr key={index}>
                                        <td><BsStars className="icon" /></td>
                                        <td><p style={{margin: '0 0 2px 0'}} className="feature">{feature.text}</p></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="card-body">
                            <button onClick={() => pay(20, 20)}
                                    style={{marginTop: '0', maxWidth: '300px', width: '100%'}}
                                    className="button_dark">
                                {t('Pricing.Pay')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <span>
                {t('Pricing.Loading')}
            </span>
                )}
            </>
        ) : (
            <></>
        )}

    </div>

);
}

export default Pricing;
