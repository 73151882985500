import React, {useEffect, useRef} from "react";
import {ToastContainer} from 'react-toastify';
import {useTranslation} from "react-i18next";

import "./Start_page_new.css";
import '../languages/i18n';

import Android from "./Imagines/Android.svg"
import AppStore from "./Imagines/AppStore.svg"
import Windows from "./Imagines/Windows.svg"

import {CgArrowRight, CgChevronDown} from "react-icons/cg";


function StartPage() {
    const { t } = useTranslation();
    const descriptions = t('Start-page.Description', {returnObjects: true});


    const elementsRef = useRef([]);
    const Ref = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            elementsRef.current.forEach((element) => {
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const elementCenter = (rect.top + rect.bottom) / 2;
                    const distanceFromCenter = Math.abs(windowHeight / 2 - elementCenter - 0.05 * windowHeight);

                    const maxDistance = Math.max(windowHeight / 2 - 100, 100);
                    if (distanceFromCenter < 100) {
                        element.style.opacity = 1
                        // element.style.color = "red"
                    } else if (distanceFromCenter > 100 && distanceFromCenter < maxDistance) {
                        element.style.opacity = 1 - distanceFromCenter / maxDistance;
                        // element.style.color = "green"
                    } else {
                        element.style.opacity = 0
                        // element.style.color = "black"
                    }
                }
            });
        };

        // Обработчик прокрутки
        window.addEventListener("scroll", handleScroll);

        // Очистка обработчика при размонтировании компонента
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="page-container">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />
            <section className="section-main title-site">
                <div className="background-main background-title-site">
                    <div className="gradient-main gradient-title-site"></div>
                    <div className="noise-main noise-title-site"/>
                </div>

                <div className="header-buttons">
                    <button className="button-line-main button-enter-title-site">
                        <span>
                            Войти
                        </span>
                        <CgArrowRight style={{marginRight: '-7px'}} size={28}/>
                    </button>

                    <button className="button-line-main button-languages-title-site">
                        <span>
                            Ru
                        </span>
                    </button>

                </div>

                <div className="title-group">
                    <div className='title-string'>
                        <span className="title">
                            GOSTLINK
                        </span>
                        <span className="sign">
                            (VPN)
                        </span>
                        <div className="red-point-main red-point-title"></div>
                    </div>
                    <span className="manifest">
                        Просто - быстро
                    </span>
                    <button className="button-line-main button-to-prise-line-main" style={{gap: '10px'}}>
                        <span>
                            Приобрести
                        </span>
                        <CgChevronDown style={{marginTop: '5px', marginRight: '-7px'}} size={32}/>
                    </button>
                </div>
            </section>

            <section className="section-main product-description-block">
                <div className="background-main background-product-description">
                    <div className="gradient-main gradient-product-description"></div>
                    <div className="noise-main noise-product-description"/>
                </div>

                <div className="product-description-content">
                    {descriptions.map((item, index) => (
                        <div className="product-description-element" key={index}
                             ref={(el) => (elementsRef.current[index] = el)}>
                            <div className="title-description-element">
                                <div className="red-point-main"></div>
                                <span className="title-text-description-element">
                                    {item.title}
                                </span>
                            </div>
                            <p className="description-element-text">
                                {item.text}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="product-info-section section-main">
                <div className="background-product-info background-main">
                    <div className="gradient-product-info gradient-main"></div>
                    <div className="noise-product-info noise-main"/>
                </div>

                <div className="content-product-prise">
                    <div className='title-cost-string-product-prise'>
                        <span className="title-title-cost-string">
                            Стоимость
                        </span>
                        <span className="sign-title-cost-string">
                            (месячная подписка)
                        </span>
                    </div>

                    <table className="payments-list-product-prise">
                        <tbody>
                        <tr>
                            <td><span className="sign-payments-list">Скидка 10% на первый платёж</span></td>
                            <td><span className="sign-payments-list" style={{marginLeft: "30px", textDecoration: "line-through", textDecorationColor: "#E40000"}}>&nbsp;390 ₽&nbsp;</span></td>
                            <td><span className="price-payments-list">320 ₽</span></td>
                            <td><div className="red-point-main" style={{marginLeft: "10px"}}></div></td>
                        </tr>
                        <tr>
                            <td colSpan="2"><span className="sign-payments-list">Последующие платежи</span></td>
                            <td><span className="price-payments-list">390 ₽</span></td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="benefit-list-product-prise">
                        <h1>Что вы получите:</h1>
                        <span>Безлимит трафика<span>—</span></span>
                        <span>Без ограничений скорости<span>—</span></span>
                        <span>Доступ к иностранным сервисам<span>—</span></span>
                        <span>Работа с российскими банками<span>—</span></span>
                        <span>Отсутствие рекламы<span>—</span></span>
                    </div>

                    <button className="button-line-main button-buy-product-prise">
                        <span>
                            Купить
                        </span>
                    </button>
                </div>


                <div className="content-product-app">
                    <h1 className="title-product-app">Скачать приложение</h1>
                    <div className="buttons-download-product-app">
                        <button className="button-line-main button-product-app">
                            <img src={Android}/>
                        </button>
                        <button className="button-line-main button-product-app">
                            <img src={AppStore} style={{marginRight: "10px", marginLeft: "-10px"}}/>
                            <div className="red-point-main red-point-product-app"></div>
                        </button>
                        <button className="button-line-main button-product-app">
                            <img src={Windows} style={{marginRight: "10px", marginLeft: "-10px"}}/>
                            <div className="red-point-main red-point-product-app"></div>
                        </button>
                    </div>
                    <div className="sign-marker-product-app">
                        <div className="red-point-main"></div>
                        <span>Приложение находится в разработке</span>
                    </div>
                </div>

                <div className="content-support">
                    <h1 className="title-support">Есть вопросы?</h1>
                    <div className="button-block-support">
                        <div className="sign-support">
                            <h1>Напишите в поддержку:</h1>
                            <span>круглосуточно</span>
                        </div>
                        <button className="button-line-main button-support">
                            <span>
                                Telegram
                            </span>
                        </button>
                        <button className="button-line-main button-support">
                            <span>
                                info@gostlink.ru
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="footer-section section-main">
                <div className="background-footer background-main">
                    <div className="gradient-footer gradient-main"></div>
                    <div className="noise-footer noise-main"/>
                </div>

                <hr/>

                <div className="content-footer">
                    <span>© 2021 — 2024 ООО "Каннт"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ИНН: 4011033196</span>
                    <button className="button-line-main button-footer">
                        <span>
                            Публичная оферта
                        </span>
                    </button>
                </div>
            </section>
        </div>

    );
}

export default StartPage;







