import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {useTranslation} from "react-i18next";

import "./Profile_new.css";
import '../languages/i18n';
import 'react-toastify/dist/ReactToastify.css';

import profile from "./Imagines/Photo-profile.svg"
import Apple_logo_device from "./Imagines/Apple-logo-device.svg"
import Android_logo_device from "./Imagines/Android-logo-device.svg"
import Windows_logo_device from "./Imagines/Windows-logo-device.svg"

import {CgArrowRight} from "react-icons/cg";
import Android from "./Imagines/Android.svg";
import AppStore from "./Imagines/AppStore.svg";
import Windows from "./Imagines/Windows.svg";

function Profile() {
    const navigate = useNavigate();

    return (
        <div className="user-console-page">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />

            {/*<div className="background-top-user-console background-main">*/}
            {/*    <div className="gradient-top-user-console gradient-main"></div>*/}
            {/*    <div className="noise-top-user-console noise-main"/>*/}
            {/*</div>*/}
            {/*<div className="background-bottom-user-console background-main">*/}
            {/*    <div className="gradient-bottom-user-console gradient-main"></div>*/}
            {/*    <div className="noise-bottom-user-console noise-main"/>*/}
            {/*</div>*/}

            <div className="user-console-content-main">
                <section className="account-info-main">
                    <div className="account-info-block profile-block-main">
                        <div className="info-list-account-info">
                            <span>georgelagodich@gmail.ru</span>
                            <div><div className="red-point-main"></div><span>Нет подписки</span></div>
                            <button>Сменить аккаунт</button>
                        </div>
                        <img src={profile}/>
                    </div>
                </section>

                <section className="user-console-main">
                    <div className="referral-block-user-console profile-block-main">
                        <div className="group-elements-title-profile-main">
                            <div className="signature-referral">
                                <div className="red-point-main"></div>
                                <h1>Реферальная программа</h1>
                            </div>
                            <button className="button-user-console-main">
                            <span>
                                Подробнее
                            </span>
                                <CgArrowRight style={{marginRight: '-7px'}} size={42}/>
                            </button>
                        </div>
                    </div>

                    <div className="sale-block-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Оформить подписку</h1>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-sale-block content-profile-block-main">
                            <div className="content-section-sale-block">
                                <table className="cost-description-sale-block">
                                    <tbody>
                                    <tr>
                                        <td><span>Первый платёж</span></td>
                                        <td className="cost"><span>320 ₽</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Последующие платежи</span></td>
                                        <td className="cost"><span>390 ₽</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="content-section-sale-block">
                                <div className="cost-benefits-sale-block">
                                    <h1>Приемущества подписки:</h1>
                                    <span>—&nbsp;&nbsp;&nbsp;Безлимит трафика</span>
                                    <span>—&nbsp;&nbsp;&nbsp;Без ограничений скорости</span>
                                    <span>—&nbsp;&nbsp;&nbsp;Доступ к иностранным сервисам</span>
                                    <span>—&nbsp;&nbsp;&nbsp;Работа с российскими банками</span>
                                    <span>—&nbsp;&nbsp;&nbsp;Отсутствие рекламы</span>
                                </div>
                            </div>
                            <button className="button-user-console-main">Купить</button>
                        </div>
                    </div>

                    <div className="cancelled-description-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Информация о подписке</h1>
                                <div className="status-title-profile-main">
                                    <span>Отменена</span>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-cancelled-description content-profile-block-main">
                            <table className="cost-description-cancelled-description">
                                <tbody>
                                <tr>
                                    <td><h1>Возобновить подписку</h1></td>
                                    <td className="cost"><span>&nbsp;</span></td>
                                </tr>
                                <tr>
                                    <td><span>Первый платёж</span></td>
                                    <td className="cost"><span>320 ₽</span></td>
                                </tr>
                                <tr>
                                    <td><span>Последующие платежи</span></td>
                                    <td className="cost"><span>390 ₽</span></td>
                                </tr>
                                </tbody>
                            </table>
                            <button className="button-user-console-main">Купить</button>
                        </div>
                    </div>

                    <div className="active-description-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Информация о подписке</h1>
                                <div className="status-title-profile-main">
                                    <span>Активна</span>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-active-description content-profile-block-main">
                            <div className="first-pay-active-description">
                                <span>Следующее списание:&nbsp;&nbsp;28.12.2024</span>
                                <span className="cost">390 ₽<div></div></span>
                            </div>
                            <button className="cancel-description-active-description">Отменить подписку</button>
                        </div>
                    </div>

                    <div className="devices-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Устройства</h1>
                                <div className="status-title-profile-main">
                                    <span>Осталось 1 незарегистрированное устройство</span>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-devices content-profile-block-main">
                            <div className="device-unit-devices">
                                <div className="device-logo-devices">
                                    <img src={Apple_logo_device} style={{top: "-4px"}}/>
                                </div>
                                <div className="device-info-devices">
                                    <div className="name-data-string-devices">
                                        <span className="title-device-devices">Iphone 13 pro max</span>
                                        <span className="text-device-devices">09.10.2024</span>
                                    </div>
                                    <span className="text-device-devices">G0NCJ0WLN73F</span>
                                </div>
                                <div className="device-detach-devices">
                                    <button className="button-detach-devices button-user-console-main">Открепить</button>
                                </div>
                            </div>
                            <hr className="separator-devices"/>
                            <div className="device-unit-devices">
                                <div className="device-logo-devices">
                                    <img src={Android_logo_device}/>
                                </div>
                                <div className="device-info-devices">
                                    <div className="name-data-string-devices">
                                        <span className="title-device-devices">realme_RMX3630</span>
                                        <span className="text-device-devices">28.08.2024</span>
                                    </div>
                                    <span className="text-device-devices">a5363dd96da4c5ef</span>
                                </div>
                                <div className="device-detach-devices">
                                    <button className="button-detach-devices button-user-console-main">Открепить</button>
                                </div>
                            </div>
                            <hr className="separator-devices"/>
                            <div className="device-unit-devices">
                                <div className="device-logo-devices">
                                    <img src={Windows_logo_device}/>
                                </div>
                                <div className="device-info-devices">
                                    <div className="name-data-string-devices">
                                        <span className="title-device-devices">MateBook X Pro</span>
                                        <span className="text-device-devices">14.01.2025</span>
                                    </div>
                                    <span className="text-device-devices">B6QBB20711800081</span>
                                </div>
                                <div className="device-detach-devices">
                                    <button className="button-detach-devices button-user-console-main">Открепить</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="product-app-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Установить приложение</h1>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-product-app content-profile-block-main">
                            <div className="buttons-line-product-app">
                                <button className="button-download-app-product-app button-user-console-main">
                                    <img src={Android}/>
                                </button>
                                <button disabled className="button-download-app-product-app button-user-console-main">
                                    <img src={AppStore} style={{marginRight: "10px", marginLeft: "-10px"}}/>
                                    <div className="red-point-main red-point-product-app"></div>
                                </button>
                                <button disabled className="button-download-app-product-app button-user-console-main">
                                    <img src={Windows} style={{marginRight: "10px", marginLeft: "-10px"}}/>
                                    <div className="red-point-main red-point-product-app"></div>
                                </button>
                            </div>
                            <div className="sign-marker-product-app">
                                <div className="red-point-main"></div>
                                <span>Приложение находится в разработке</span>
                            </div>
                        </div>
                    </div>

                    <div className="support-block-user-console profile-block-main">
                        <div className="group-elements-title-profile-main">
                            <h1>Поддержка</h1>
                            <div className="buttons-line-support-block">
                                <button className="button-support-support-block button-user-console-main">Telegram</button>
                                <button className="button-support-support-block button-user-console-main">info@gostlink.ru</button>
                            </div>
                        </div>
                    </div>

                    <div className="cancel-description-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>Отмена подписки</h1>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-cancel-description content-profile-block-main">
                            <span className="text-condolences-cancel-description">
                                Мы не плачем... просто это дождь в нашем сердце. Если мы сделали что-то не так, дайте знать - возможно, мы успеем исправить ситуацию!
                            </span>
                            <span>
                                Вы решили уйти окончательно, помните: вы всегда можете вернуться, но и промокоды - не вечные.
                            </span>
                            <button className="cancel-button-cancel-description button-user-console-main">Отменить подписку</button>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    );
}

export default Profile;
